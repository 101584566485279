import React from "react";
import {
  CommonBanner,
  Container,
  Section,
  Seo,
} from "@components";
import { PageLayout } from "../components/site/layout/page-layout";

const CompanyPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <CommonBanner
        title="Mobile App Protection Innovators"
        subtitle="The Leader in End-to-End Mobile Security"
      />

      <Section>
        <Container className="py-8">
          <p className="text-[22px] md:text-[32px] font-black not-prose text-center md:text-left m-[0px] mt-[-2px] leading-[30px] md:leading-[50px]">From Silicon Glen to Silicon Valley</p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            Approov is a team of passionate developers dedicated to making the
            future of mobile secure. From our offices in Edinburgh, Scotland
            (UK), and Palo Alto, California, we focus on developing the world’s
            most complete end-to-end solution for mobile app security from the
            device into the cloud. Our solutions are used worldwide to secure
            mobile apps and their APIs in everything from automotive, financial
            services, healthcare to e-commerce, gaming and media.
          </p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            Approov is a privately held company backed by leading investors
            including Lanza TechVentures, Scottish Enterprise and Herald
            Ventures.
          </p>

          <p className="text-[22px] md:text-[32px] font-black not-prose text-center md:text-left m-[0px] mt-[-2px] leading-[30px] md:leading-[50px]">A History of Innovation</p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            The company — founded as Critical Blue Limited — was formed in
            Silicon Glen to provide cutting-edge research and development in
            code optimization and microprocessor design technologies to the
            semiconductor industry. For more than 20 years, CriticalBlue has
            been providing advanced software capabilities to the biggest names
            in technology including Intel Corporation, Thales Group, Toshiba and
            Freescale.
          </p>

          <p className="text-[22px] md:text-[32px] font-black not-prose text-center md:text-left m-[0px] mt-[-2px] leading-[30px] md:leading-[50px]">Core Expertise in Binary Level Analysis</p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            In the 2000s, our initial technology platform was built to perform
            static and dynamic analysis of ARM machine code to generate highly
            optimized processor architectures from profile-guided analysis.
            Binary-to-binary code transformation was then used to map code onto
            the custom architecture. Later, we enhanced this technology to
            perform binary translation onto fixed instruction set architectures
            while preserving vector single instruction, multiple data (SIMD)
            optimizations.
          </p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            We expanded our dynamic code analysis capabilities adding support
            for numerous processor architectures including x86 and POWER with
            32-bit and 64-bit instruction set. By expanding beyond instruction
            level dependency analysis, our interactive development environment
            enabled optimizations for thread level parallelism extraction from
            existing code. This was widely deployed to a range of customers
            along with technical consulting services for code optimization with
            a specialty in cryptographic accelerators.
          </p>

          <p className="text-[22px] md:text-[32px] font-black not-prose text-center md:text-left m-[0px] mt-[-2px] leading-[30px] md:leading-[50px]">Transitioning to Mobile</p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            In the mid-2010s, several projects related to the optimization of
            the Android runtime system for specific 64-bit chipsets added
            compiler optimization passes as well as runtime library enhancements
            to our platform. Android runtime consulting, coupled with our prior
            binary analysis and security consulting, led us to develop the key
            technology components that became Approov. The first commercial
            release of Approov was in 2017. We now serve a wide range and
            growing customer base.
          </p>

          <p className="text-[22px] md:text-[32px] font-black not-prose text-center md:text-left m-[0px] mt-[-2px] leading-[30px] md:leading-[50px]">Our Unique Advantage</p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            Our patented binary analysis expertise and technology platform
            enables us to develop highly differentiated technologies to ensure
            end-to-end mobile security.
          </p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            We predicted an emerging security risk would come with the
            widespread use of APIs in the app economy and the vulnerabilities of
            mobile apps. Tampered apps and spoofed API requests have become an
            increasing significant business security challenge. Apps need a way
            to continuously identify themselves reliably to backend systems
            using more sophisticated techniques than API keys or other secrets
            embedded within apps subject to easy exploitation.
          </p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            Leveraging our binary analysis expertise enables us to build the
            most advanced programmable and scalable runtime analysis engine
            capable of running within apps to provide real-time threat
            intelligence and runtime app self-protection (RASP).
          </p>
          <p className="text-[16.5px] mt-[10px] leading-[26px] text-center md:text-left not-prose">
            We couple this with a SaaS cloud service and Real-Time Threat
            Analysis Dashboards to efficiently and securely partition the
            analysis between the client device and the server, providing our
            award-winning and breakthrough technology in application
            attestation. Unlike AI-based behavioral solutions, we take a
            deterministic approach that ensures the lowest latency, highest
            performance and most accurate results.
          </p>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default CompanyPage;
